<template>
  <badge :type="badgeType">
    {{ $t("WALLET." + status) }}
  </badge>
</template>

<script>
import {
  STATUS_DRAFT,
  STATUS_AWAITING_SIGNATURE,
  STATUS_SIGNATURE_DELINED,
  STATUS_AWAITING_ADMIN_VALIDATION,
  STATUS_ADMIN_REFUSED,
  STATUS_PENDING,
  STATUS_BANK_REFUSED,
  STATUS_COMPLETED,
  STATUS_SENT,
  STATUS_PROCESSED,
  STATUS_FAILED,
  STATUS_GENERATED,
  STATUS_PROCESSING,
  STATUS_BANK_PROCESSING,
  TRANSACTION_STATUS_LIST,
} from "../../constants/common";

export default {
  name: "StatusBadge",
  props: {
    status: {
      type: String,
      required: true,
      /* validator: function (value) {
        return TRANSACTION_STATUS_LIST.includes(value);
      }, */
    },
  },
  computed: {
    badgeType() {
      switch (this.status) {
        case STATUS_DRAFT:
        case STATUS_GENERATED:
          return "secondary";
        case STATUS_SENT:
        case STATUS_PROCESSING:
        case STATUS_BANK_PROCESSING:
          return "primary";
        case STATUS_COMPLETED:
          case STATUS_PROCESSED :
          return "success";
        case STATUS_SIGNATURE_DELINED:
        case STATUS_ADMIN_REFUSED:
        case STATUS_BANK_REFUSED:
          case STATUS_FAILED:
          return "danger";
        case STATUS_AWAITING_ADMIN_VALIDATION:
        case STATUS_PENDING:
        case STATUS_AWAITING_SIGNATURE:
          return "warning";
        default:
          return "default";
      }
    },
  },
};
</script>
