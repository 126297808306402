export const STATUS_SENT = "sent";
export const STATUS_PROCESSED = "processed";
export const STATUS_FAILED = "failed";
export const STATUS_GENERATED = "generated";
export const STATUS_PROCESSING = "processing"
export const FILE_STATUS_LIST = {
    '':'',
    [STATUS_SENT]: "sent",
    [STATUS_PROCESSED]: "processed",
    [STATUS_PROCESSING]: "processing",
    [STATUS_FAILED]: "failed",
    [STATUS_GENERATED]: "generated",
  };
  
export const TYPE_IN = "deposit";
export const TYPE_OUT = "withdraw";
export const TRANSACTIONS_TYPES = {
  [TYPE_IN]: "deposit",
  [TYPE_OUT]: "withdraw",
};

export const BNC_BANK = "bnc";
export const BANKS = {
  [BNC_BANK]: "bnc",
};