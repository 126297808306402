export default {
  type: "dpa-accounts",
  bank: null,
  debit_data_center_code: null,
  debit_bank_format: null,
  debit_issuer_number: null,
  debit_file_name: null,
  credit_data_center_code: null,
  credit_bank_format: null,
  credit_issuer_number: null,
  credit_file_name: null,
  debit_return_account: null,
  credit_return_account: null,
  transaction_callback_url: null,
  auto_transaction_processing:false,
  relationshipNames: [
    "organization",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
};